* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif5;
}

.Intro-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #61dafb;
  padding: 6vh;
}

h1 {
  margin-bottom: 20px;
  font-weight: 500;
}

p {
  line-height: 1.5em;
  font-size: 1.2em;
  font-weight: 300;
  font-family: monospace;
  color: white;
}